import { Grid, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";

function ItOperations() {
  return (
    <Grid container display="flex" alignItems="center" spacing={7}>
      <Grid
        item
        display="flex"
        justifyContent="center"
        lg={12}
        md={12}
        sm={12}
        xs={12}>
        <Typography variant="t52_700">{"IT Operations"}</Typography>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container display="flex" flexDirection="row" pb="20px">
          <Grid item lg={1} md={1} sm={1} xs={1} color="common.blue">
            <EastIcon />
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography variant="t18_400">
              {
                "Protecting the reliability of your business critical functions with our 24X7 highly efficient and reliable support team."
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container display="flex" flexDirection="row" pb="20px">
          <Grid item lg={1} md={1} sm={1} xs={1} color="common.blue">
            <EastIcon />
          </Grid>
          <Grid item lg={11} md={11} sm={11} xs={11}>
            <Typography variant="t18_400">
              {
                "Enhancing the performance and availability of your databases through robust database administration."
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default ItOperations;
