import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Img1 from "../../asset/img1.svg";
import Img2 from "../../asset/img2.svg";
import Img3 from "../../asset/img3.svg";
import Img4 from "../../asset/img4.svg";

function Images() {
  return (
    <Grid container spacing={5}>
      <Grid item lg={4}>
        <Box>
          <img
            src={Img1}
            alt={"NoImage"}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
          <Typography vatiant="t24_700_L56">{"Software Delivery"}</Typography>
        </Box>
      </Grid>
      <Grid item lg={8}>
        <Box>
          <img
            src={Img2}
            alt={"NoImage"}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
          <Typography vatiant="t24_700_L56">{"Automation & Testing"}</Typography>
        </Box>{" "}
      </Grid>
      <Grid item lg={8}>
        <Box>
          <img
            src={Img3}
            alt={"NoImage"}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
          <Typography vatiant="t24_700_L56">
            {"Business Intelligence & Analytics"}
          </Typography>
        </Box>{" "}
      </Grid>
      <Grid item lg={4}>
        <Box>
          <img
            src={Img4}
            alt={"NoImage"}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
          <Typography vatiant="t24_700_L56">{"Operations Support"}</Typography>
        </Box>{" "}
      </Grid>
    </Grid>
  );
}

export default Images;
