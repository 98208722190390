import { CAREER_EMAIL, CONTACT_US_EMAIL } from "../actionTypes";

const initialState = {
  contact: [],
  carrer: [],
};

function AdminReducer(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CONTACT_US_EMAIL:
      return { ...state, contact: payload };

    case CAREER_EMAIL:
      return { ...state, carrer: payload };

    default:
      return state;
  }
}

export default AdminReducer;
