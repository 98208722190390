import React, { useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  useJsApiLoader,
  Polyline,
  Marker,
  InfoWindow,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { Box, Divider, Grid, Tab, Tabs, Typography } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import PropTypes from "prop-types";
import MapTabs from "./mapTab";

const containerStyle = {
  // width: '400px',
  // height: '400px'
  width: "100%",
  height: "680px",
  overflow: "hidden",
  position: "relative",
};

const center = {
  lat: 9.939093,
  lng: 78.121719,
};

function TabPanel(props) {
  const { children, value, index, d, ...other } = props;
  const [location, setLocation] = useState();

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function MapContainer(props) {
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: "AIzaSyAqZz-jimyZlgXDrVwPcqHU50lBx4f8M0Q",
  });
  const [coordinates, setCoordinates] = useState([
    {
      id: 1,
      latitude: 12.990955047466512,
      longitude: 80.21827047238786,
      country: "India",
      company_name: "VeeTee Technologies",
      company_address:
        "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India.",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
    {
      id: 2,
      latitude: 51.5072,
      longitude: 0.1276,
      country: "UK",
      company_name: "VeeTee Technologies",
      company_address: "UK",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
    {
      id: 3,
      latitude: 19.8968,
      longitude: 155.5828,
      country: "US",
      company_name: "VeeTee Technologies",
      company_address: "US",
      phone_number: "+91-44-4211 7914",
      mail: "veetee@vttech.in",
    },
  ]);
  const [map, setMap] = React.useState(null);
  const [currentIndex, setcurrentIndex] = React.useState(0);
  const [currentOpenIndex, setcurrentOpenIndex] = React.useState("");
  const [OpenIndex, setOpenIndex] = React.useState({});
  const [currentCloseIndex, setcurrentCloseIndex] = React.useState(0);
  const [showingInfoWindow, setshowingInfoWindow] = React.useState(true);
  const [activeMarker, setactiveMarker] = React.useState({});
  const [stores, setStores] = React.useState([]);
  const [openInfoWindowMarkerId, setOpenInfoWindowMarkerId] =
    React.useState(true);
  const tempinitsform = useRef();

  const onLoad = React.useCallback(function callback(map) {
    const bounds = new window.google.maps.LatLngBounds();
    map.fitBounds(bounds);
    setMap(map);
  }, []);

  const onUnmount = React.useCallback(function callback(map) {
    setMap(null);
  }, []);

  const initsform = () => {
    if (coordinates) {
      setStores(coordinates);
      const marker = {};
      coordinates.forEach((d, i) => {
        marker[i] = true;
      });
      setOpenIndex(marker);
    }
  };
  tempinitsform.current = initsform;
  useEffect(() => {
    tempinitsform.current();
  }, [coordinates]);

  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    console.log(newValue, "erererere");
  };
  // const onMarkerClick = (props, marker, e) => {
  //   console.log("onMarker", props, 'Maker', marker, 'event', e)

  //   let index = coordinates.findIndex(f => f.latitude === props.position.lat)
  //   let Data = [...coordinates]
  //   Data[index].activeMarker = props
  //   Data[index].showingInfoWindow = true
  //   setCoordinates(Data)
  //   setcurrentIndex(index)
  //   console.log("hdg", Data, 'jdh', stores)
  // }

  // const handleToggleOpen = (marker) => {
  //   console.log('kj',marker)
  //   if(marker){
  //     setcurrentOpenIndex(marker)
  //     // setOpenInfoWindowMarkerId(true);
  //   }
  // };

  // const handleToggleClose = (marker) => {
  //   console.log('kj',marker)
  //   if(marker){
  //     setcurrentOpenIndex('')
  //     // setOpenInfoWindowMarkerId(false);
  //   }
  // };

  console.log("jd", coordinates, "jdh", stores);
  return isLoaded ? (
    <GoogleMap
      mapContainerStyle={containerStyle}
      // center={{ lat: 9.939093, lng: 78.121719 }}
      initialCenter={{ lat: 9.939093, lng: 78.121719 }}
      center={{
        lat: stores[currentIndex]?.latitude,
        lng: stores[currentIndex]?.longitude,
      }}
      zoom={14}
      onLoad={onLoad}
      onUnmount={onUnmount}>
      {/* Child components, such as markers, info windows, etc. */}
      <Polyline
        path={coordinates.map((d) => ({
          lat: d.latitude,
          lng: d.longitude,
        }))}
        geodesic={true}
        options={{
          strokeColor: "#ff2527",
          strokeOpacity: 0.75,
          strokeWeight: 2,
        }}
      />
      {coordinates.map((d, index) => {
        return (
          <Marker
            key={index}
            onClick={() => {
              setOpenIndex((d) => ({ ...d, [index]: true }));
            }}
            label={
              <Typography color="#ff2527">{"VeeTee Technologies"}</Typography>
            }
            position={{ lat: d.latitude, lng: d.longitude }}>
            {OpenIndex[index] && (
              <InfoWindow
                key={index}
                onCloseClick={() => {
                  {
                    setOpenIndex((d) => ({ ...d, [index]: false }));
                  }
                }}>
                <Typography>{"fffffffffffff"}</Typography>
              </InfoWindow>
            )}
          </Marker>
        );
      })}
    </GoogleMap>
  ) : (
    <></>
  );
}

export default React.memo(MapContainer);
