import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import { Card, Grid } from "@mui/material";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function MapTabs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Card
      sx={{
        bgcolor: "common.black",
        color: "common.white",
        p: "35px",
        width: "40%",
        m: "20px",
        borderRadius: 2,
      }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example">
            <Tab
              label={
                <Typography variant="t24_500" color="common.white">
                  {"India"}
                </Typography>
              }
              {...a11yProps(0)}
            />
            <Tab
              label={
                <Typography variant="t24_500" color="common.white">
                  {"United Kingdom"}
                </Typography>
              }
              {...a11yProps(1)}
            />
            <Tab
              label={
                <Typography variant="t24_500" color="common.white">
                  {"United States"}
                </Typography>
              }
              {...a11yProps(2)}
            />
          </Tabs>
        </Box>
        <TabPanel value={value} index={0}>
          <Grid container spacing={3} display="flex" flexDirection="row">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_700">{"VeeTee Technologies"}</Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t20_500">
                {
                  "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India."
                }{" "}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <LocalPhoneIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"+91-44-4211 7914"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <EmailOutlinedIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"veetee@vttech.in"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={1}>
          <Grid container spacing={3} display="flex" flexDirection="row">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_700">
                {"VeeTee Technologies UK"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t20_500">
                {
                  "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India."
                }{" "}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <LocalPhoneIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"+91-44-4211 7914"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <EmailOutlinedIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"veetee@vttech.in"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
        <TabPanel value={value} index={2}>
          <Grid container spacing={3} display="flex" flexDirection="row">
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t24_700">
                {"VeeTee Technologies US"}
              </Typography>
            </Grid>
            <Grid item lg={12} md={12} sm={12} xs={12}>
              <Typography variant="t20_500">
                {
                  "65, 1st Floor, Sankaran Avenue, Radhakrishnan Street, Velachery 100ft Road, Chennai - 600 042. TN, India."
                }{" "}
              </Typography>
            </Grid>
            <Grid item lg={5} md={5} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <LocalPhoneIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"+91-44-4211 7914"}
                </Typography>
              </Box>
            </Grid>
            <Grid item lg={3} md={3} sm={6} xs={12}>
              <Box direction="row" display="flex" alignItems="center">
                <EmailOutlinedIcon size="small" color="common.black" />
                <Typography variant="t16_500" pl="10px">
                  {"veetee@vttech.in"}
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </TabPanel>
      </Box>
    </Card>
  );
}
