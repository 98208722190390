import React from "react";
import CPSTTemp from "../projects/tempCpst";

export default function SupplyChainDefectCpst() {
  const clientName = "Client Name";

  const technologyContent = "Java/J2EE, Oracle";

  const projectContent = [
    {
      projectContent:
        "Shipping Requirements Management application is used by a chemical manufacturing unit of a multinational consumer goods corporation.",
    },
    {
      projectContent:
        "This web application helps the manufacturer to record and manage shipping instructions for a massive range of products that are to be followed by 3rd parties involved in shipping.",
    },
  ];
  const servicesContent = "Web Application Development";

  return (
    <CPSTTemp
      clientName={clientName}
      technologyContent={technologyContent}
      projectContent={projectContent}
      servicesContent={servicesContent}
    />
  );
}
