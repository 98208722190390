import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import JoinHandsWithUs from "../../components/projects/joinHand";
import ShippingExecutionCpst from "../../components/shippingExecutionManagement/cpstShippingExecution";
import ShippingExecutionCs from "../../components/shippingExecutionManagement/csShippingExecution";
import ShippingExecutionFB from "../../components/shippingExecutionManagement/fbShippingExecution";
import ShippingExecutionMain from "../../components/shippingExecutionManagement/mainShippingExecution";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

export default function ShippingExecutionManagement() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.yellow",
        }}
        maxHeight="50vh">
        <ShippingExecutionMain />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionCpst />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionCs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionFB />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <JoinHandsWithUs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}
