import { Box, Card, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import ConceptChart from "../../components/contactUs/conceptChart";
import GetInTouch from "../../components/contactUs/form";
import Map from "../../components/contactUs/map";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import MapTabs from "../../components/contactUs/mapTab";
import "../../index.css";

function ContactUs() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.green"}
        ButtonBgcolor={"common.orange"}
        logo={LogoBlack}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.black"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ backgroundColor: "common.green" }}
        className="appMargin">
        <ConceptChart />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <GetInTouch />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Map />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <MapTabs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default ContactUs;
