import http from "../http-common";

class Service {
  contactEmail(data) {
    console.log("service", data);
    return http.post(`/admin/contact`, data);
  }
  careerEmail(data) {
    return http.post(`/admin/carrer`, data);
  }
}
export default new Service();
