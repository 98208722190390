import { Grid, Typography } from "@mui/material";
import React from "react";

function Intro() {
  return (
    <Grid container spacing={7} display="flex" flexDirection="row-reverse">
      <Grid item lg={2} md={6} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={6} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={6} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={6} md={12} sm={6} xs={2}></Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Typography variant="t52_700">
          {"Every Service We Provide Is Our Customer And Your Customer Centric"}
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Intro;
