import { BrowserRouter } from "react-router-dom";
import ThemeConfig from "./theme/index";
import Router from "./routes/index";
import QuickAccess from "./layout/quickAccess";
import configureStore, { history } from "./redux/store/store";
import { Provider } from "react-redux";
import { Container } from "@mui/material";
import "./App.css";

const store = configureStore();

function App() {
  console.log("1");
  return (
    <>
      <Provider store={store}>
        <ThemeConfig>
          <BrowserRouter history={history}>
            <Router />
          </BrowserRouter>
        </ThemeConfig>
      </Provider>
    </>
  );
}

export default App;
