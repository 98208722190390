import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Intro from "../../components/skills/intro";
import SoftwareDev from "../../components/skills/softwareDev";
import SoftwareTesting from "../../components/skills/softwareTesting";
import ItOperatiions from "../../components/skills/itOperations";
import Cards from "../../components/skills/cards";
import Skill from "../../components/skills/skill";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import ServiceR from "../../components/skills/serviceR";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function Skills() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.blue"}
        ButtonBgcolor={"transparent"}
        logo={LogoWhite}
        ButtonBorder="whitesmoke"
        bText="common.white"
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.blue"
        maxHeight="50vh">
        <Skill />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceR />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SoftwareDev />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SoftwareTesting />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ItOperatiions />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Cards />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Skills;
