import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { exploreSolution } from "../../label";
import UsImg from "../../asset/us.png";
import AImg2bg from "../../asset/aImg2bg.png";
import AImg2C1 from "../../asset/aImg2C1.png";
import AImg2C2 from "../../asset/aImg2C2.png";
import AImg2Side from "../../asset/aImg2Side.png";

function Us() {
  return (
    <Grid container spacing={7} display="flex" alignItems="center">
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid
          container
          spacing={5}
          display="flex"
          alignItems="center"
          color="common.white">
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography color="common.black" variant="t18_700_L32">
              {"WHO WE ARE"}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t60_700_L60">
              {" "}
              {"We strive to do things differently"}{" "}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t18_500">
              {" "}
              {
                "We are a team of young, energetic, well-trained engineers with passion for technology and innovation. Our staff members are qualified, and certified individuals that demonstrate high degree of professionalism and display excellent communication."
              }{" "}
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {
              "We work around the clock, and adapt to any time zone. We can scale the teams to fit the project needs and maintain the quality standards."
            }
          </Grid>
          <Grid item lg={4} md={6} sm={4} xs={12}>
            {" "}
            <Button
              sx={{
                backgroundColor: "transpareny",
                border: `1px solid whitesmoke`,
                color: "common.white",
                width: "100%",
                height: "100%",
              }}>
              <Typography variant="t18_500">{exploreSolution}</Typography>
            </Button>{" "}
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 550 },
            minHeight: { xs: 250, sm: 450, md: 540 },
            maxWidth: { xs: 260, sm: 460, md: 550 },
            minWidth: { xs: 250, sm: 450, md: 540 },
            // // border: "2px solid black",,
          }}>
          <img
            src={AImg2bg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "70%",
              width: "80%",
              top: "30%",
              left: "10%",
            }}
          />
          <img
            src={UsImg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "95%",
              left: "10%",
            }}
          />
          <img
            src={AImg2C1}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "15%",
              width: "15%",
              top: "87%",
              left: "70%",
            }}
          />
          <img
            src={AImg2C2}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "20%",
              width: "20%",
              top: "20.1%",
              left: "5%",
            }}
          />
          <img
            src={AImg2Side}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "40%",
              width: "40%",
              top: "70%",
              right: "65%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Us;
