import { Grid } from "@mui/material";
import React from "react";
import DataAnalytics from "../../components/services/dataAnalytics";
import WebAppDev from "../../components/services/webAppDevelopment";
import WebScrap from "../../components/services/webScraping";
import UiAndUx from "../../components/services/uiAndux";
import ServiceApproach from "../../components/services/serviceApproach";
import OracleDtoT from "../../components/services/oracleDtoT";
import OracleDb from "../../components/services/oracleDb";
import ServiceStandard from "../../components/services/serviceStandard";
import Intro from "../../components/services/intro";
import Service from "../../components/services/service";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import We from "../../components/services/we";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function Services() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.orange"}
        ButtonBgcolor={"transparent"}
        logo={LogoWhite}
        ButtonBorder="whitesmoke"
        bText="common.white"
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        maxHeight="50vh"
        bgcolor="common.orange">
        <Service />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <We />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WebAppDev />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WebScrap />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <DataAnalytics />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <UiAndUx />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <OracleDtoT />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <OracleDb />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceStandard />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ServiceApproach />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Services;
