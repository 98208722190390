import { CAREER_EMAIL, CONTACT_US_EMAIL } from "../actionTypes";
import Service from "../../services/index";
import { ErrorAlert, FailLoad, ListLoad } from "../load";

export const ContactEmailAction = (data) => async (dispatch) => {
  try {
    console.log("action", data);
    const res = await Service.contactEmail(data);
    if (res.status === 200) {
      dispatch({
        type: CONTACT_US_EMAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    ErrorAlert(dispatch, err);
  }
};

export const CareerEmailAction = (data) => async (dispatch) => {
  try {
    const res = await Service.careerEmail(data);
    if (res.status === 200) {
      dispatch({
        type: CAREER_EMAIL,
        payload: res.data,
      });
    }
  } catch (err) {
    ErrorAlert(dispatch, err);
  }
};
