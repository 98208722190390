import { Grid, Typography } from "@mui/material";
import React from "react";
import EastIcon from "@mui/icons-material/East";

function Intro() {
  return (
    <Grid
      container
      spacing={7}
      display="flex"
      flexDirection="row-reverse"
      alignItems="center"
      justifyContent="center">
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={2} md={12} sm={6} xs={6}></Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}></Grid>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Typography variant="t52_700">
          {
            "Our Developers, Testers & Operators Promote DevOps Culture And Work As One IT Team"
          }
        </Typography>
      </Grid>
    </Grid>
  );
}

export default Intro;
