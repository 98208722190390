import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import JoinHandsWithUs from "../../components/projects/joinHand";
import ShippingRequirementsCpst from "../../components/shippingRequirements/cpstShippingRequirements";
import ShippingRequirementsCs from "../../components/shippingRequirements/csShippingRequirement";
import ShippingRequirementsFB from "../../components/shippingRequirements/fbShippingRequirements";
import ShippingRequirementsMain from "../../components/shippingRequirements/shippingRequirementsMain";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

export default function ShippingRequirements() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.yellow",
        }}
        maxHeight="50vh">
        <ShippingRequirementsMain />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingRequirementsCpst />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingRequirementsCs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingRequirementsFB />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <JoinHandsWithUs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}
