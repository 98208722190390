import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage1 from "./gridTemp1";
import Laptop from "../../asset/lap4.svg"


export default function RetailErpSolution() {
  const title = "Retail ERP Solution";
  const subTitle = "Web Application Development";
  const benefit = "Meeting unique defect management needs not met by COTS  ";
  const launch = "6 Weeks";
  const project = "Maintaining";
  const button = "View Details";

  const content = [
    {
      content:
        "Supply Chain Defect Management application is used by a multinational consumer goods manufacturer that has a supply chain covering Europe, North America and Asia Pacific",
    },
    {
      content:
        "This web application helps the manufacturer in preventing and managing defects that occur in their unique supply chain process.",
    },
    {
      content:
        "Java/J2EE, Oracle",
    },
  ];
  return (
    <GridTempTextImage1
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
    />
  );
}
