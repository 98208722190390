import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { getInTouch } from "../label";
import { useNavigate } from "react-router";
import { Link, Tab, Tabs } from "@mui/material";
import AppDrawer from "./drawer";
import "../index.css";

const pages = [
  "About Us",
  "Service",
  "Skills",
  "Projects",
  "Careers",
  "Contact Us",
];
const settings = ["Profile", "Account", "Dashboard", "Logout"];

function ResponsiveAppBar({
  navBarBgColor,
  ButtonBgcolor,
  logo,
  ButtonBorder,
  bText,
  text,
  hoverColor,
  hBorderColor,
}) {
  let navigate = useNavigate();

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const navigatePage = (page) => {
    console.log("hhh");
    if (page === "Contact Us") {
      navigate("/contactUs");
    } else if (page === "Service") {
      navigate("/service");
    } else if (page === "Skills") {
      navigate("/skills");
    } else if (page === "About Us") {
      navigate("/aboutUs");
    } else if (page === "Careers") {
      navigate("/careers");
    } else if (page === "Projects") {
      navigate("/projects");
    }

    // switch (page) {
    //   case "Home":
    //     navigate("/");
    //     break;
    //   case "Contact Us":
    //     navigate("/contactUs");
    //     break;
    //   case "Service":
    //     navigate("/service");
    //     break;
    //   default:
    //     navigate("/");
    // }
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const [state, setState] = React.useState(false);

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, open });
  };

  return (
    <AppBar
      position="static"
      sx={{ backgroundColor: navBarBgColor, boxShadow: "none" }}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Box
            sx={{
              display: {
                xs: "none",
                md: "flex",
                padding: "20px 70px",
              },
              mr: 1,
            }}>
            <img
              src={logo}
              alt={"NoImage"}
              onClick={() => {
                navigate("/");
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
                ":hover": { SVGAnimateElement: "auto" },
              }}
            />
          </Box>

          {/* for mobile */}

          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "flex", md: "none" },
            }}>
            <Tooltip title="Options">
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={toggleDrawer(true)}
                color="inherit">
                <MenuIcon />
              </IconButton>
            </Tooltip>
            <AppDrawer
              toggleDrawer={toggleDrawer}
              state={state}
              page={pages}
              navigatePage={navigatePage}
            />
            {/* <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}>
              {pages.map((page) => (
                <MenuItem
                  key={page}
                  onClick={() => {
                    navigatePage(page);
                  }}>
                  <Typography variant="t16_400" color={text} textAlign="center">
                    {page}
                  </Typography>
                </MenuItem>
              ))}
            </Menu> */}
          </Box>
          <Box
            sx={{
              display: { xs: "flex", md: "none", padding: "20px 70px" },
              mr: 1,
            }}>
            <img
              src={logo}
              alt={"NoImage"}
              onClick={() => {
                navigate("/");
              }}
              style={{
                height: "100%",
                width: "100%",
                backgroundColor: "transparent",
              }}
            />
          </Box>
          <Box sx={{ flexGrow: 1, display: { xs: "none", md: "flex" } }}>
            {pages.map((page) => (
              <Button
                key={page}
                onClick={() => {
                  navigatePage(page);
                }}
                sx={{
                  my: 2,
                  color: "white",
                  display: "block",
                  "&:active": {
                    boxShadow: "none",
                    backgroundColor: "#0062cc",
                    borderColor: "#005cbf",
                    border: "1.5px black",
                  },
                  "&:focus": {
                    boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
                  },
                  borderBottom: "",
                }}>
                <Typography
                  variant="t16_400"
                  color={text}
                  sx={{ boderBottom: "1.5px solid red" }}>
                  {page}
                </Typography>
              </Button>
            ))}
          </Box>
          <Box sx={{ padding: "0% 7%" }}>
            <Button
              sx={{
                backgroundColor: ButtonBgcolor,
                border: `1.5px solid ${ButtonBorder}`,
                color: bText,
                width: "100%",
                height: "100%",
                ":hover": {
                  color: hoverColor,
                  border: `1.5px solid ${hBorderColor}`,
                },
              }}>
              <Typography variant="t18_500">{getInTouch}</Typography>
            </Button>
          </Box>
          {/* <Box sx={{ flexGrow: 0 }}>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box> */}
        </Toolbar>
      </Container>
    </AppBar>
  );
}
export default ResponsiveAppBar;
