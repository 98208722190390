import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import Eclipse from "../../asset/eclipse.png";
import Man from "../../asset/man.png";
import HalfCircle from "../../asset/halfcircle.png";
import Note from "../../asset/note.png";

export default function OurProducts() {
  return (
    <Grid container spacing={5}>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={5} color="common.white">
          <Grid item lg={12}>
            <Typography
              variant="t60_700"
              sx={
                {
                  // color: "common.white",
                  // display: "flex",
                  // justifyContent: "center",
                  // p: "20px 0px 20px 0px",
                }
              }>
              Our Products
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography
              // sx={{
              //   fontSize: "20px",
              //   fontWeight: "400",
              //   color: "white",
              //   fontFamily: "Helvetica Neue",
              //   lineHeight: "23px",
              // }}
              variant="t23_400">
              Tempus urna et pharetra pharetra massa. Consequat id porta nibh
              venenatis cras sed. Ridiculus mus mauris vitae ultricies leo
              integer malesuada nunc.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={6}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 490 },
            minHeight: { xs: 250, sm: 450, md: 480 },
            maxWidth: { xs: 260, sm: 460, md: 490 },
            minWidth: { xs: 250, sm: 450, md: 480 },
            // // border: "2px solid black",,
          }}>
          <img
            src={Eclipse}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "80%",
              height: "80%",
              top: "10%",
              bottom: "0%",
              left: "10%",
            }}
          />
          <img
            src={Man}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "100%",
              height: "95%",
              right: "13%",
            }}
          />
          <img
            src={HalfCircle}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "15%",
              height: "30%",
              top: "5%",
              bottom: "0%",
              left: "20%",
            }}
          />
          <img
            src={Note}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "30%",
              height: "20",
              top: "70%",
              bottom: "0%",
              left: "66%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
