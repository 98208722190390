import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Chart from "../../asset/conceptChart.svg";

function ConceptChart() {
  return (
    <Grid container spacing={3} p="20px">
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        sx={{ display: "flex", justifyContent: "center" }}>
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 360, sm: 560, md: 760 },
            minHeight: { xs: 350, sm: 550, md: 750 },
            maxWidth: { xs: 360, sm: 710, md: 1320 },
            minWidth: { xs: 350, sm: 700, md: 1310 },
            // // border: "2px solid black",,
          }}>
          <img
            src={Chart}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default ConceptChart;
