import React, { useEffect } from "react";
import { Box, Grid, Typography } from "@mui/material";
import Main from "../../components/careers/main";
import CareersForm from "../../components/careers/form";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function Careers() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.grey"}
        ButtonBgcolor={"common.orange"}
        logo={LogoWhite}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        // p="2% 7%"
        className="appMargin"
        sx={{
          backgroundColor: "common.grey",
        }}
        maxHeight="75vh">
        <Main />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <CareersForm />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Careers;
