import { Grid, Typography } from "@mui/material";
import React from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

export default function FeaturesBenifitsTemp(props) {
  const { featuresBenifitsContentLeft, featuresBenifitsContentRight } = props;
  return (
    <Grid container>
      <Grid item lg={12}>
        <Grid container>
          <Grid item lg={12} pb="20px">
            <Typography variant="t52_700">Features & Benifits</Typography>
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} pb="15px">
            {featuresBenifitsContentLeft.map((m) => (
              <>
                <Grid container display={"flex"} spacing={2}>
                  <Grid item color="common.yellow">
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item lg={11} md={11} sm={11} xs={11} pb="20px">
                    <Typography variant="t18_400">
                      {m.featuresBenifitsContentLeft}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid item lg={6} md={12} sm={12} xs={12} pb="15px">
            {featuresBenifitsContentRight.map((m) => (
              <>
                <Grid container display={"flex"} spacing={2}>
                  <Grid item color="common.yellow">
                    <CheckCircleIcon />
                  </Grid>
                  <Grid item lg={11} md={11} sm={11} xs={11} pb="20px">
                    <Typography variant="t18_400">
                      {m.featuresBenifitsContentRight}
                    </Typography>
                  </Grid>
                </Grid>
              </>
            ))}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
