import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import medall from "../../asset/medall.svg";
import orange from "../../asset/orangeCalltaxi.svg";
import dynamic from "../../asset/dynamic.svg";
import pg from "../../asset/pg.svg";

function Details() {
  return (
    <Grid container spacing={7}>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="t64_700" color="common.orange">
            {" "}
            {"14+"}{" "}
          </Typography>
          <Typography variant="t16_400_L19">
            {" "}
            {"Years of Experiance"}{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="t64_700" color="common.orange">
            {" "}
            {"5+"}{" "}
          </Typography>
          <Typography variant="t16_400_L19"> {"Countries clients"} </Typography>
        </Box>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="t64_700" color="common.orange">
            {" "}
            {"300K+"}{" "}
          </Typography>
          <Typography variant="t16_400_L19"> {"Hours of work"} </Typography>
        </Box>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Box display="flex" flexDirection="column">
          <Typography variant="t64_700" color="common.orange">
            {" "}
            {"100%"}{" "}
          </Typography>
          <Typography variant="t16_400_L19">
            {" "}
            {"Renewal of Contracts"}{" "}
          </Typography>
        </Box>
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} pt="50px">
        <Grid container spacing={5} display="flex">
          <Grid
            item
            lg={6}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <Typography variant="t52_700">
              {"Nimble, Deft, Diligent, Able and Efficient Consultants"}
            </Typography>
          </Grid>
          <Grid item lg={6} md={6} sm={6} xs={6}></Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
          <Grid
            item
            lg={8}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-end">
            <Typography variant="t18_500">
              {
                "VeeTee is leading Information Technology services organization, based out of Chennai, India, catering several industries including Manufacturing, Consumer Product, Education and Health Care and serving clients in 5 countries steering their digital mission."
              }
            </Typography>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
          <Grid
            item
            lg={8}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-end">
            <Typography variant="t18_500">
              {
                "With nearly a decade of experience of developing and managing systems and working with global enterprises, we empower and enable businesses with our cutting edge deliverables to not just meet their needs but to exceed their targets."
              }
            </Typography>
          </Grid>
          <Grid item lg={4} md={12} sm={12} xs={12}></Grid>
          <Grid item lg={2} md={4} sm={4} xs={12}>
            <Button
              sx={{
                backgroundColor: "common.orange",
                border: `1.5px solid #FD7E3B`,
                color: "common.white",
                width: "100%",
                height: "100%",
                ":hover": {
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                },
              }}>
              <Typography variant="t18_500">{"Know More"}</Typography>
            </Button>
          </Grid>
          <Grid item lg={10} md={10} sm={10} xs={10}></Grid>
          <Grid item lg={4} md={4} sm={4} xs={4}></Grid>
          <Grid
            item
            lg={8}
            md={12}
            sm={12}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <Typography variant="t32_700_L56">{"Trusted by"}</Typography>
          </Grid>
          <Grid item lg={4} md={2} sm={2} xs={0}></Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <img
              src={medall}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
                color: "red",
              }}
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <img
              src={orange}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <img
              src={dynamic}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Grid>
          <Grid
            item
            lg={2}
            md={2}
            sm={2}
            xs={12}
            display="flex"
            justifyContent="flex-start">
            <img
              src={pg}
              alt={"NoImage"}
              style={{
                height: "100%",
                width: "100%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Details;
