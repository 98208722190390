import { Box, Grid, IconButton, Typography } from "@mui/material";
import React from "react";
import LogoOrange from "../asset/logoVeeTee.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Link from "@mui/material/Link";
import { useNavigate } from "react-router";

function QuickAccess() {
  let navigate = useNavigate();
  const preventDefault = (event) => event.preventDefault();
  return (
    <Grid container display="flex" spacing={7}>
      <Grid item lg={4} md={4} sm={6} xs={12}>
        <Grid
          container
          spacing={3}
          display="flex"
          flexDirection="row"
          alignItems="center">
          <Grid item lg={2.5} md={2.5} sm={4} xs={4}>
            <Box sx={{}}>
              <img
                src={LogoOrange}
                alt={"NoImage"}
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  height: "100%",
                  width: "100%",
                  backgroundColor: "transparent",
                  ":hover": { SVGAnimateElement: "auto" },
                }}
              />
            </Box>
          </Grid>
          <Grid item lg={9.5} md={9.5} sm={8} xs={8}>
            <Typography variant="t32_600">{"VeeTee Technologies"}</Typography>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <IconButton
              sx={{
                ":hover": {
                  color: "common.orange",
                  backgroundColor: "common.lightOrange",
                },
                color: "common.quickAccess",
              }}>
              <FacebookIcon />
            </IconButton>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <IconButton
              sx={{
                ":hover": {
                  color: "common.orange",
                  backgroundColor: "common.lightOrange",
                },
                color: "common.quickAccess",
              }}>
              <TwitterIcon />
            </IconButton>
          </Grid>
          <Grid item lg={1} md={1} sm={2} xs={2}>
            <IconButton
              sx={{
                ":hover": {
                  color: "common.orange",
                  backgroundColor: "common.lightOrange",
                },
                color: "common.quickAccess",
              }}>
              <LinkedInIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={3} md={3} sm={6} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
          onClick={preventDefault}>
          <Typography variant="t18_700_L15" pb="20px" color="common.black">
            {" "}
            {"Solutions"}{" "}
          </Typography>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Software Delivery"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Automation & Testing"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Business Intelligence & Analytics"}
            </Typography>
          </Link>
          <Link href="#" underline="hover" color="common.quickAccess">
            <Typography variant="t15_400">{"Operations Support"}</Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item lg={2} md={2} sm={6} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
          onClick={preventDefault}>
          <Typography variant="t18_700_L15" pb="20px" color="common.black">
            {" "}
            {"VeeTee"}{" "}
          </Typography>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"About Us"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Services"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Careers"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"Contact Us"}
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid item lg={2} md={2} sm={6} xs={12}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            flexWrap: "wrap",
          }}
          onClick={preventDefault}>
          <Typography variant="t18_700_L15" pb="20px" color="common.black">
            {" "}
            {"Contacts"}{" "}
          </Typography>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"veetee@vttech.in"}
            </Typography>
          </Link>
          <Link href="#" underline="hover">
            <Typography variant="t15_400" color="common.quickAccess">
              {"+91-44-4211 7914"}
            </Typography>
          </Link>
        </Box>
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
        color="common.end">
        <Box direction="row">
          <Typography variant="t13_400" pr="20px">
            {"© 2021 VeeTee Technologies. All rights reserved."}
          </Typography>
          <Typography variant="t13_400" pr="20px">
            {"Privacy Policy"}
          </Typography>
          <Typography variant="t13_400" pr="20px">
            {"Terms of Use"}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}

export default QuickAccess;
