import { Box, Card, Typography } from "@mui/material";
import React from "react";

function HomeCardsTemp(props) {
  const { img, title, content } = props;
  return (
    <Card
      sx={{
        height: "138px",
        border: (theme) => `1px solid ${theme.palette.grey[200]}`,
        // boxShadow: (theme) => `0px 12px 23px ${theme.palette.grey[200]}`,
        p: "24px",
        borderRadius: "10px",
        backgroundColor: "common.white"
      }}>
      <Box display="flex" height="100%" alignItems="center">
        <Box width="35%">
          <img
            src={img}
            alt={"NoImage"}
            style={{
              height: "100%",
              width: "100%",
            }}
          />
        </Box>
        <Box>
          <Typography variant="t18_700">{title}</Typography>
          <Box display="flex" mt="4px" alignItems="center">
            <Typography variant="t12_400">{content}</Typography>
          </Box>
        </Box>
      </Box>
    </Card>
  );
}

export default HomeCardsTemp;
