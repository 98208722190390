import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Presentation from "../../asset/Rectangle39.svg";

export default function Main() {
  return (
    <Grid container spacing={7}>
      <Grid item lg={12} md={12} sm={12} xs={12}>
        <Grid container spacing={5} color="common.white">
          <Grid item lg={5} md={6} sm={12} xs={12}>
            <Typography
              variant="t40_700_L49"
              sx={{
                color: "white",
                display: "flex",
                // justifyContent: "center",
              }}>
              Careers
            </Typography>
          </Grid>
          <Grid
            item
            lg={7}
            md={6}
            sm={12}
            xs={12}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}>
            <Typography variant="t20_400">
              We pride ourselves on attracting, developing and engaging an
              unconventional mix of talent that enables us to be the think
              different, act different, deliver kind of agency we are today.
              Build your career with us - entry level or experienced
              professionals.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid />
      <Grid
        item
        // pt="40px"
        lg={12}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent={"center"}>
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 160, sm: 220, md: 480, lg: 480 },
            minHeight: { xs: 150, sm: 210, md: 470, lg: 470 },
            maxWidth: { xs: 260, sm: 590, md: 820, lg: 1420 },
            minWidth: { xs: 250, sm: 580, md: 810, lg: 1410 },
            // border: "2px solid black",,
            // top: "10%",
          }}>
          {" "}
          <img
            src={Presentation}
            alt=""
            style={{
              position: "absolute",
              height: "100%",
              width: "100%",
              top: "0%",
              left: "0%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}
