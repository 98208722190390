import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";

export default function JoinHandsWithUs() {
  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      spacing={6}
      sx={{
        backgroundColor: "common.lightOrange",
      }}
    >
      <Grid
        item
        lg={6}
        md={10}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Typography variant="t52_700">
          We just want to have the best people working for us.
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent={"center"}
      >
        <Grid item>
          <Grid container>
            <Grid item>
              <Typography variant="t18_400_L24">
                We pride ourselves on attracting, developing and engaging an
                unconventional mix of talent that enables us to be the think
                different, act different, deliver kind of agency we are today.
              </Typography>
            </Grid>

            <Grid
              item
              lg={4}
              md={4}
              sm={6}
              xs={12}
              pt="20px"
              display="flex"
              justifyContent={"center"}
            >
              <Button
                sx={{
                  backgroundColor: "common.yellow",
                  border: `1.5px solid #FFB800`,
                  color: "common.white",
                  width: "100%",
                  ":hover": { color: "common.yellow" },
                }}
              >
                <Typography>Join Hands with us</Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
