import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import Mission from "../../components/aboutUs/mission";
import EndTemp from "../../components/aboutUs/endTemp";
import History from "../../components/aboutUs/history";
import Images from "../../components/aboutUs/images";
import Strength from "../../components/aboutUs/strength";
import Us from "../../components/aboutUs/us";
import Intro from "../../components/aboutUs/intro";
import ResponsiveAppBar from "../../layout/appBar";
import LogoOrange from "../../asset/logoOrange.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function AboutUs() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.black"}
        ButtonBgcolor={"common.orange"}
        logo={LogoOrange}
        ButtonBorder={"#FD7E3B"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"#FD7E3B"}
        hBorderColor={"#FD7E3B"}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        bgcolor="common.black"
        // maxHeight="120%"
        color="common.white">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Mission />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <History />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        backgroundColor="common.orange"
        className="appMargin">
        <Us />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Images />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Strength />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        backgroundColor="common.lightOrange"
        className="appMargin">
        <EndTemp />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default AboutUs;
