import { Button, Grid, TextField, Typography } from "@mui/material";
import React from "react";

export default function RequestProposal() {
  return (
    <Grid
      container
      display="flex"
      alignItems="center"
      justifyContent="center"
      spacing={5}
      sx={{
        backgroundColor: "common.lightOrange",
      }}>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Typography variant="t52_700">
          Request Proposal To Access More Project Cases
        </Typography>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid container spacing={5}>
          <Grid item lg={12} md={6} sm={12}>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "18px",
                lineHeight: "24px",
                // width: "593px",
              }}>
              Pellentesque habitant morbi tristique senectus. Sit amet mauris
              commodo quis imperdiet massa. Duis ut diam quam nulla porttitor.
              Id diam maecenas ultricies mi.
            </Typography>
          </Grid>
          <Grid item lg={12} md={6} sm={12} display="flex" alignItems="center">
            <Grid container spacing={5}>
              <Grid item lg={6} md={6} sm={8} xs={12}>
                <TextField
                  placeholder="Enter Your Email"
                  fullWidth={true}
                  sx={{ backgroundColor: "common.white" }}
                />
              </Grid>
              <Grid
                item
                lg={3}
                md={6}
                sm={4}
                xs={11}
                display="flex"
                justifyContent={"center"}>
                <Button
                  sx={{
                    backgroundColor: "common.yellow",
                    border: `1.5px solid #FFB800`,
                    color: "common.white",
                    width: "100%",
                    height: "100%",
                    ":hover": {
                      color: "common.yellow",
                      border: `1.5px solid #FFB800`,
                    },
                  }}>
                  <Typography variant="t18_500">Submit</Typography>
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Typography>
              Faucibus a pellentesque sit amet porttitor eget dolor morbi non.
              Feugiat nisl
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
