export const emailValidation = (value) => {
  console.log("nameee", value);
  const emailRegex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return !(!value || emailRegex.test(value) === false);
};

export const phoneValidation = (value) => {
  console.log("nameee", value);
  const phoneRegex = /^\d{10}$/;
  return !(!value || phoneRegex.test(value) === false);
};
