import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import ServiceImg1 from "../../asset/servicesImg1.svg";

function We() {
  return (
    <Grid container>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Box
          display="flex"
          justifyContent="center"
          sx={{
            position: "relative",
            maxHeight: { xs: 90, sm: 360, md: 260, lg: 360 },
            minHeight: { xs: 80, sm: 350, md: 250, lg: 350 },
            maxWidth: { xs: 260, sm: 560, md: 410, lg: 620 },
            minWidth: { xs: 250, sm: 550, md: 400, lg: 610 },
            // border: "2px solid black",,
          }}>
          <img
            src={ServiceImg1}
            alt="NoImage"
            style={{ height: "90%", width: "90%" }}
          />
        </Box>
      </Grid>
      <Grid item lg={6} md={6} sm={12} xs={12}>
        <Grid container spacing={3}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t32_700">We Listen to Understand</Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t18_400">
              Our service starts with understanding your business architecture
              and customer journeys unlike some of our competitors who jump
              straight into solving your problems with technology.
            </Typography>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t18_400">
              We then sprint to understand and solve your problems with cost
              effective and cutting edge technologies.
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default We;
