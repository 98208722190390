import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage1 from "./gridTemp1";
import Laptop from "../../asset/lap.svg"

export default function CustomDutyManagement() {
    const title = "Customs Duty Management";
    const subTitle ="Web Application Development"
    const benefit = "Import Duty Exemption, Reduction of FTE resources";
    const launch ="8 Weeks"
    const project = "Maintaining for past 3 years";
    const button ="View Details"
    const pageUrl = "/projects/customsDutyManage"

  const content = [
    {
      content:
        "Customs Duty Management application is used by a multinational consumer goods corporation that imports raw materials from Asia and Europe into the UK.",
    },
    {
      content:
        "This web application (integrated with SAP) helps the importer in benefiting from the HMRC customs import duty exemption for all raw materials imported from within Europe.",
    },
  ];

  return (
    <GridTempTextImage1
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
      pageUrl = {pageUrl}
    />
  );
}
