import { Grid } from "@mui/material";
import React from "react";
import BussinessAnalytics from "../../components/home/bussinessAnalytics";
import Intro from "../../components/home/intro";
import UiDesign from "../../components/home/uiDesign";
import WebScraping from "../../components/home/webScraping";
import Strength from "../../components/aboutUs/strength";
import EndTemp from "../../components/aboutUs/endTemp";
import Details from "../../components/home/details";
import ResponsiveAppBar from "../../layout/appBar";
import LogoWhite from "../../asset/logoWhite.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function Home({ setNavBarBgColor }) {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.homeGreen"}
        ButtonBgcolor={"common.orange"}
        logo={LogoWhite}
        ButtonBorder={"transparent"}
        bText={"common.white"}
        text={"common.white"}
        hoverColor={"common.white"}
        hBorderColor={"#FFFFFF"}
        colorr={"red"}
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.homeGreen">
        <Intro />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <BussinessAnalytics />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        backgroundColor="common.blue">
        <WebScraping />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <UiDesign />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Details />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Strength />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        bgcolor="common.lightOrange">
        <EndTemp />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Home;
