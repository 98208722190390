import React from "react";
import GridTempTextImage from "../gridTempTextImage";
import Chart from "../../asset/webApp.svg";

function WebAppDev() {
  const title = "Web Application Development";

  const content = [
    {
      content:
        "Achieve your Enterprise / E-commerce / Content Management needs precisely with customisation",
    },
    {
      content:
        "Empower your customers and employees with real time quality information.",
    },
    {
      content:
        "Feel consistent experience on desktop, tablet and mobile devices with responsive designs.",
    },
    {
      content:
        "Experience flexibility in using different browsers with browser compatibility design.",
    },
    {
      content:
        "Control who can enter what and who can view what using User rights management.",
    },
    {
      content:
        "Encourage and alert your users with push notification feature. ",
    },
  ];
  return <GridTempTextImage title={title} image={Chart} content={content} />;
}

export default WebAppDev;
