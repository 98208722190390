import { Grid } from "@mui/material";
import React, { useEffect } from "react";
import CustomDutyCpst from "../../components/customsDutyManagement/cpstCustomDutyManagement";
import CustomDutyMain from "../../components/customsDutyManagement/main";
import AssetManagementSystem from "../../components/projects/assetManagementSystem";
import CustomDutyManagement from "../../components/projects/customDutyManagement";
import GainConfidence from "../../components/projects/gainConfidence";
import Maps from "../../components/projects/maps";
import MedicalDiagnosisManagement from "../../components/projects/medicalDiagnosisManagement";
import OurProducts from "../../components/projects/ourProducts";
import RequestProposal from "../../components/projects/requestProposal";
import RetailErpSolution from "../../components/projects/retailErpSolution";
import ShippingExecutionManagement from "../../components/projects/shippingExecutionManagement";
import ShippingRequirementsManagement from "../../components/projects/shippingRequirementsManagement";
import SupplyChainDefectManagement from "../../components/projects/supplyChainDefectManagement";
import TruckTrackingTool from "../../components/projects/truckTrackingTool";
import WeStartProject from "../../components/projects/weStartProject";
import WholesaleDistributionManagement from "../../components/projects/wholesaleDistributionManagement";
import YellowCardTemp from "../../components/yellowCardTemp";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import AreYouWilling from "../../components/projects/areYouWilling";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function Projects() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{ backgroundColor: "common.yellow" }}
        maxHeight="40vh">
        <OurProducts />
      </Grid>
      <Grid
        className="appMargin"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        bgcolor="common.paper1">
        <AreYouWilling />
      </Grid>
      <Grid
        className="appMargin"
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        bgcolor="common.paper1">
        <GainConfidence />
      </Grid>
      <Grid className="appMargin" item lg={12} md={12} sm={12} xs={12}>
        <WeStartProject />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <CustomDutyManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingExecutionManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SupplyChainDefectManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <ShippingRequirementsManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <MedicalDiagnosisManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <WholesaleDistributionManagement />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <TruckTrackingTool />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <AssetManagementSystem />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <RetailErpSolution />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <Maps />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <RequestProposal />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default Projects;
