import { Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import JoinHandsWithUs from "../../components/projects/joinHand";
import SupplyChainDefectCpst from "../../components/supplyChainDefect/cpstSupplyChain";
import SupplyChainDefectCs from "../../components/supplyChainDefect/csSupplyChainDefect";
import SupplyChainDefectFB from "../../components/supplyChainDefect/fbSupplyChainDefect";
import SupplyChainDefectMain from "../../components/supplyChainDefect/mainsupplyChainDefect";
import ResponsiveAppBar from "../../layout/appBar";
import LogoBlack from "../../asset/logoBlack.png";
import QuickAccess from "../../layout/quickAccess";
import "../../index.css";

function SupplyChainDefectPage() {
  return (
    <Grid container>
      <ResponsiveAppBar
        navBarBgColor={"common.yellow"}
        ButtonBgcolor={"transparent"}
        logo={LogoBlack}
        ButtonBorder={"black"}
        bText={"common.black"}
        text="common.black"
      />
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        p="10px 100px"
        sx={{
          backgroundColor: "common.yellow",
        }}
        maxHeight="50vh">
        <SupplyChainDefectMain />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SupplyChainDefectCpst />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SupplyChainDefectCs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <SupplyChainDefectFB />
      </Grid>
      <Grid
        item
        lg={12}
        md={12}
        sm={12}
        xs={12}
        className="appMargin"
        sx={{
          backgroundColor: "common.lightOrange",
        }}>
        <JoinHandsWithUs />
      </Grid>
      <Grid item lg={12} md={12} sm={12} xs={12} className="appMargin">
        <QuickAccess />
      </Grid>
    </Grid>
  );
}

export default SupplyChainDefectPage;
