import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import Img from "../../asset/serviceImg.png";
import sImg1bg from "../../asset/sImg1bg.png";
import sImg1Tri from "../../asset/sImg1Tri.png";
import sImg1Web from "../../asset/sImg1Web.png";

function Service() {
  return (
    <Grid container spacing={7} sx={{ color: "common.white" }}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Grid container spacing={5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {" "}
            <Typography variant="t60_700">{"Services"}</Typography>{" "}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t18_500">
              {
                "We are your strategic IT outsourcing partner that can help you transform your IT operations, for maximum positive business impact. Our core activities are:"
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItem="center"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // border: "2px solid black",
          }}
        >
          <img
            src={sImg1bg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "80%",
              width: "80%",
              top: "20%",
              left: "3%",
            }}
          />
          <img
            src={sImg1Tri}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "25%",
              width: "25%",
              top: "25%",
              right: "80%",
            }}
          />
          <img
            src={Img}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "90%",
            }}
          />
          <img
            src={sImg1Web}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "35%",
              width: "50%",
              top: "70%",
              left: "50%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Service;
