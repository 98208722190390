// ----------------------------------------------------------------------

function pxToRem(value) {
  return `${value / 16}rem`;
}

function responsiveFontSizes({ sm, md, lg }) {
  return {
    "@media (min-width:600px)": {
      fontSize: pxToRem(sm),
    },
    "@media (min-width:900px)": {
      fontSize: pxToRem(md),
    },
    "@media (min-width:1200px)": {
      fontSize: pxToRem(lg),
    },
  };
}

const FONT_PRIMARY = "Helvetica Neue";

const typography = {
  fontFamily: FONT_PRIMARY,
  fontWeightRegular: 400,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  h1: {
    fontWeight: 700,
    lineHeight: 80 / 64,
    fontSize: pxToRem(40),
    ...responsiveFontSizes({ sm: 52, md: 58, lg: 64 }),
  },
  h2: {
    fontWeight: 700,
    lineHeight: 64 / 48,
    fontSize: pxToRem(32),
    ...responsiveFontSizes({ sm: 40, md: 44, lg: 48 }),
  },
  h3: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(24),
    ...responsiveFontSizes({ sm: 26, md: 30, lg: 32 }),
  },
  h4: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(20),
    ...responsiveFontSizes({ sm: 20, md: 24, lg: 24 }),
  },
  h5: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(18),
    ...responsiveFontSizes({ sm: 19, md: 20, lg: 20 }),
  },
  h6: {
    fontWeight: 700,
    lineHeight: 28 / 18,
    fontSize: pxToRem(17),
    ...responsiveFontSizes({ sm: 18, md: 18, lg: 18 }),
  },
  subtitle1: {
    fontWeight: 600,
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  subtitle2: {
    fontWeight: 600,
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  body1: {
    lineHeight: 1.5,
    fontSize: pxToRem(16),
  },
  body2: {
    lineHeight: 22 / 14,
    fontSize: pxToRem(14),
  },
  caption: {
    lineHeight: 1.5,
    fontSize: pxToRem(12),
  },
  overline: {
    fontWeight: 700,
    lineHeight: 1.5,
    fontSize: pxToRem(12),
    letterSpacing: 1.1,
    textTransform: "uppercase",
  },
  button: {
    fontWeight: 700,
    lineHeight: 24 / 14,
    fontSize: pxToRem(14),
    textTransform: "capitalize",
  },
  t12_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "14.32px",
  },
  t13_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "13px",
    fontWeight: 400,
    lineHeight: "15.03px",
    color: "#061238",
  },
  t14_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
  },
  t14_500: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: "17.09px",
  },
  t15_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "15px",
    fontWeight: 400,
    lineHeight: "15.03px",
  },
  t16_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "18.4px",
  },
  t16_400_L19: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "19.09px",
  },
  t16_400_L24: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "16px",
    lineHeight: "24px",
  },
  t16_500: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "16px",
    lineHeight: "24px",
  },
  t18_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
  },
  t18_400_L24: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "24px",
  },
  t18_400_L28: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "28px",
  },
  t18_400_L34: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "34px",
  },
  t18_400_L22: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "18px",
    lineHeight: "22px",
  },
  t18_500: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "24px",
  },
  t18_500_L35: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "18px",
    lineHeight: "35px",
  },
  t18_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "21.98px",
  },
  t18_700_L32: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "32px",
  },
  t18_700_L15: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "18px",
    lineHeight: "15.03px",
  },
  t20_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "20px",
    lineHeight: "23.46px",
  },
  t20_500: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "20px",
    lineHeight: "24.42px",
  },
  t22_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "22px",
    lineHeight: "30px",
  },
  t24_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "36px",
  },
  t24_400_L56: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 400,
    fontSize: "24px",
    lineHeight: "56px",
  },
  t24_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "32px",
  },
  t24_700_L29: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "29.3px",
  },
  t24_700_L56: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "24px",
    lineHeight: "56px",
  },
  t24_500: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "24px",
    lineHeight: "36px",
  },
  t32_700_L42: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "42px",
  },
  t32_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "32px",
  },
  t32_700_L56: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "32px",
    fontWeight: 700,
    lineHeight: "56px",
  },
  t52_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 400,
    lineHeight: "70px",
  },
  t52_400_L56: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 400,
    lineHeight: "56px",
  },
  t40_700: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "50px",
  },
  t40_700_L49: {
    fontFamily: "HelveticaNeue",
    fontStyle: "normal",
    fontSize: "40px",
    fontWeight: 700,
    lineHeight: "49px",
  },
  t52_400_L70: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 400,
    lineHeight: "70px",
  },
  t52_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 700,
    lineHeight: "56px",
  },
  t52_700_L60: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 700,
    lineHeight: "60px",
  },
  t60_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "60px",
    fontWeight: 400,
    lineHeight: "56px",
  },
  t52_700_L70px: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "52px",
    fontWeight: 700,
    lineHeight: "70px",
  },
  t60_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "60px",
    fontWeight: 700,
    lineHeight: "56px",
  },
  t60_700_L60: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "60px",
    fontWeight: 700,
    lineHeight: "60px",
  },
  t64_400: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "64px",
    fontWeight: 400,
    lineHeight: "78px",
  },
  t64_700: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontSize: "64px",
    fontWeight: 700,
    lineHeight: "78px",
  },
};

export default typography;
