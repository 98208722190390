import ContactUs from "../pages/contactUs";
import Home from "../pages/home";
import Services from "../pages/services";
import { Routes, Route } from "react-router-dom";
import Skills from "../pages/skills";
import NotFound from "../utils/notFound";
import AboutUs from "../pages/aboutUs";
import Careers from "../pages/careers";
import Projects from "../pages/projects";
import CustomDutyManagement from "../pages/customDutyManagement/index";
import CustomsDutyManagement from "../pages/customDutyManagement/index";
import ShippingExecutionManagement from "../pages/shippingExecutionManagement";
import SupplyChainDefect from "../pages/supplyChainDefect";
import ShippingRequirements from "../pages/shippingRequirements";
import { useState } from "react";

function App() {
  const [navBarBgColor, setNavBarBgColor] = useState("common.homeGreen");
  console.log("1");
  return (
    <>
      <Routes>
        <Route
          path="/"
          element={<Home setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route path="/contactUs" element={<ContactUs />} exact />
        <Route
          path="/service"
          element={<Services setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/skills"
          element={<Skills setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/aboutUs"
          element={<AboutUs setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/notFound"
          element={<NotFound setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/careers"
          element={<Careers setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/projects"
          element={<Projects setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/projects/customsDutyManage"
          element={
            <CustomsDutyManagement setNavBarBgColor={setNavBarBgColor} />
          }
          exact
        />
        <Route
          path="/projects/shippingExecutionManage"
          element={
            <ShippingExecutionManagement setNavBarBgColor={setNavBarBgColor} />
          }
          exact
        />
        <Route
          path="/projects/supplyChainDefect"
          element={<SupplyChainDefect setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
        <Route
          path="/projects/shippingRequirements"
          element={<ShippingRequirements setNavBarBgColor={setNavBarBgColor} />}
          exact
        />
      </Routes>
    </>
  );
}

export default App;
