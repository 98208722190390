import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { exploreSolution } from "../../label";
import Img from "../../asset/aboutImg.png";
import ImgBg from "../../asset/aImg1bg.png";
import YellowCircle from "../../asset/aImg1YellowCircle.png";
import Side from "../../asset/aImg1Side.png";

function Intro() {
  return (
    <Grid container spacing={7} display="flex">
      <Grid item lg={6}>
        <Grid container spacing={5}>
          <Grid item lg={5}>
            {" "}
            <Typography variant="t60_700">{"About Veetee"}</Typography>{" "}
          </Grid>
          <Grid item lg={12}>
            <Typography variant="t18_500">
              {
                "Veetee delivers solutions that suit your needs and build customized solutions to answer your challenges. Our delivery on our promise is based on efficiency, scalability and longevity. Our team of experts deliver with an adequate   implementation methodology, meeting time-lines that award the organizations an edge over other players in the marketplace. More than a decade into this realm, we have been developing and managing systems in almost every business vertical. We furnish the most comprehensive suite of managed IT services and our proven service portfolio meets varying customer needs. Our implementation services and hosted IT solutions, add value to their respective businesses. The Applications Managed Services team with its extensive expertise in managing the entire Application Landscape right from development, support and maintenance ensure business continuity. We ensure their applications function seamlessly. Having reached a certain level of stability, our team works on improvements such as intelligent automation to accelerate proficiency and reduce human intervention. The Cloud migration is prepared through our proprietary 4S model in alignment with the business owners of the applications. Our passion and adeptness in creating software applications for PCs, the web, and mobile, is not only cost-effective with extensive use of open source technologies but leverage cloud computing for maximum value. Besides managing and maintaining their IT infrastructure, we conduct astute management of Databases and Operating Systems through an end-to-end Infrastructure Maintenance and Support process. Our consultants handle log tickets; resolve issues and coordinate with OEMs of the respective technology products as and when required. We have earned a reputation of quality service to all our customers with an efficient, seamless and worry-free ‘Remote IT department’."
              }
            </Typography>
          </Grid>
          <Grid item lg={5}>
            <Button
              sx={{
                backgroundColor: "transparent",
                border: `1px solid white`,
                color: "common.white",
                width: "100%",
                height: "100%",
              }}
            >
              {exploreSolution}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // border: "2px solid black",
          }}
        >
          <img
            src={ImgBg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "70%",
              width: "70%",
              top: "25%",
              left: "11%",
            }}
          />
          <img
            src={Img}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "80%",
            }}
          />
          <img
            src={YellowCircle}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "20%",
              width: "20%",
              top: "15%",
              left: "71%",
            }}
          />
          <img
            src={Side}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "25%",
              width: "35%",
              top: "60%",
              left: "60%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Intro;
