import { Box, Grid, Typography } from "@mui/material";
import React from "react";
import SkillIntro from "../../asset/skillsIntro.png";
import SkillBg from "../../asset/skillImg1bg.png";
import SkillC from "../../asset/skillImg1C.png";
import SkillWeb from "../../asset/skillImg1web.png";

function Skill() {
  return (
    <Grid container spacing={7}>
      <Grid item lg={6} md={12} sm={12} xs={12}>
        <Grid container spacing={5}>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            {" "}
            <Typography variant="t60_700">{"Our Core Skills"}</Typography>{" "}
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
            <Typography variant="t18_500">
              {
                "Tempus urna et pharetra pharetra massa. Consequat id porta nibh venenatis cras sed. Ridiculus mus mauris vitae ultricies leo integer malesuada nunc. "
              }
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // border: "2px solid black",,
          }}>
          <img
            src={SkillBg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "70%",
              width: "70%",
              top: "20%",
              left: "8%",
            }}
          />
          <img
            src={SkillIntro}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "90%",
            }}
          />
          <img
            src={SkillC}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "25%",
              width: "25%",
              top: "8.5%",
              right: "79.4%",
            }}
          />
          <img
            src={SkillWeb}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "30%",
              width: "40%",
              top: "75%",
              left: "45%",
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}

export default Skill;
