import axios from "axios";

let baseURL = "http://localhost:4000";
let wsUrl = "ws://localhost:4000";

const servers = {
  local: {
    // host : "pos.vtt.im",
    // base_url : "https://posserver.vtt.im",
    // web_socket_url :'wss://posserver.vtt.im',

    host: "localhost",
    base_url: "http://localhost:4000",
    // web_socket_url: "ws://localhost:4000",
  },
  dev: {
    host: "pos.vtt.im",
    base_url: "https://posserver.vtt.im",
    // web_socket_url: "wss://posserver.vtt.im",
  },
  prod: {
    vtckl: {
      host: "vtckl.salesplay.in",
      base_url: "https://vtcklserver.salesplay.in",
      // web_socket_url: "wss://vtcklserver.salesplay.in",
    },
  },
};

// if (process.env.NODE_ENV === "production") {
//   baseURL = "https://vttwebsite.vtt.im";
//   wsUrl = "wss://vttwebsite.vtt.im";
// }

const location = window.location;

export const baseURL1 =
  location.hostname === "vttwebsite.vtt.im"
    ? servers.dev.base_url
    : location.hostname === "vtckl.salesplay.in";

const instance = axios.create({
  baseURL,
  timeout: 100000,
  headers: {
    "Content-type": "application/json",
  },
});

export const base_url = baseURL;
export const ws_url = wsUrl;
export default instance;
