import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg4 from "../../asset/homeImg4.svg";
import HImg4Bg from "../../asset/hImg4bg.svg";
import HImg4Circle1 from "../../asset/hImg4Circle1.svg";
import HImg4Circle2 from "../../asset/hImg4Circle2.svg";
import HImg4Dashboard from "../../asset/hImg4Dashboard.svg";

function UiDesign() {
  const cardContent = [
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
  ];
  return (
    <Grid container display="flex" color="common.black" spacing={7}>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // // border: "2px solid black",,
          }}>
          <img
            src={HImg4Bg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "80%",
              width: "80%",
              top: "20.2%",
              left: "5%",
            }}
          />
          <img
            src={HomeImg4}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "100%",
              width: "80%",
              left: "10%",
            }}
          />
          <img
            src={HImg4Circle1}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "15%",
              width: "15%",
              top: "40%",
              left: "5.3%",
            }}
          />
          <img
            src={HImg4Circle2}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "15%",
              width: "15%",
              left: "75%",
              top: "13%",
            }}
          />
          <img
            src={HImg4Dashboard}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "40%",
              width: "40%",
              top: "70%",
              left: "60%",
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid container spacing={5}>
          <Grid item lg={7}>
            <Typography variant="t60_700">{"UI UX Desing"}</Typography>
          </Grid>
          <Grid item lg={9}>
            <Typography variant="t18_500">
              {
                "Aenean pharetra augue in diam rutrum, ac aliquam velit scelerisque. In ut faucibus justo."
              }
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Button
              sx={{
                backgroundColor: "transparent",
                border: `1px solid black`,
                color: "common.black",
                width: "100%",
                height: "100%",
              }}>
              {exploreSolution}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={5}>
          <Grid item lg={2}>
            <Typography variant="t32_700">{"Case Studies"}</Typography>
            <Box display={"flex"} flexDirection="row" pt="20px">
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                  mr: "10px",
                }}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                }}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Grid>
          {cardContent.map((m) => (
            <Grid item lg={4} md={6} sm={9} xs={12}>
              <HomeCardsTemp title={m.title} content={m.content} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default UiDesign;
