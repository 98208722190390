import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg1 from "../../asset/homeImg1.png";
import HImg1 from "../../asset/hImg1.png";
import HImg1Triangle from "../../asset/hImg1Triangle.png";
import HomeImg1Circle from "../../asset/hImg1Ellipse.png";
import HomeImg1Rect from "../../asset/hImg1Rectangle.png";

function Intro() {
  const cardContent = [
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
  ];
  return (
    <Grid container display="flex" color="common.white" spacing={7}>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <Grid container spacing={5}>
          <Grid item lg={10}>
            <Typography variant="t60_700">
              {"We assure you to deliver the BEST or NOTHING!"}
            </Typography>
          </Grid>
          <Grid item lg={9}>
            <Typography variant="t18_500">
              {
                "Veetee Technologies has been breaking new ground with its leading-edge software skill sets. As a systems integrator and technology support system, it has provided intelligent technical solutions and consulting services. It caters to different businesses of various scales for effective heterogeneous information resources and services, anytime and anywhere."
              }
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Button
              sx={{
                backgroundColor: "transparent",
                border: `1px solid whitesmoke`,
                color: "common.white",
                width: "100%",
                height: "100%",
              }}
            >
              {exploreSolution}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center"
      >
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // border: "2px solid black",
          }}
        >
          <img
            src={HomeImg1Circle}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "70%",
              height: "70%",
              top: "30%",
              bottom: "0%",
              left: "20%",
            }}
          />
          <img
            src={HomeImg1Rect}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "40%",
              height: "50%",
              top: "25.5%",
              bottom: "0%",
              left: "20%",
              borderRadius: " 0% 0% 0% 0%",
            }}
          />

          <img
            src={HomeImg1}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "100%",
              height: "95%",
              // bottom: "40%",
              // left: "55%",
            }}
          />
          <img
            src={HImg1Triangle}
            alt="..."
            style={{
              position: "absolute",
              width: "20%",
              height: "20%",
              top: "85%",
              bottom: "0%",
              left: "76.5%",
            }}
          />
          <img
            src={HImg1}
            alt="..."
            style={{
              position: "absolute",
              width: "30%",
              height: "30%",
              top: "79%",
              bottom: "0%",
              right: "57%",
            }}
          />
        </Box>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={5}>
          <Grid item lg={2}>
            <Typography variant="t32_700">{"Case Studies"}</Typography>
            <Box display={"flex"} flexDirection="row" pt="20px">
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid whitesmoke`,
                  color: "common.white",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                  mr: "10px",
                }}
              >
                <ArrowBackIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid whitesmoke`,
                  color: "common.white",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `1.5px solid #FD7E3B`,
                  },
                }}
              >
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Grid>
          {cardContent.map((m) => (
            <Grid item lg={4} md={6} sm={9} xs={12}>
              <HomeCardsTemp title={m.title} content={m.content} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Intro;
