import { Grid, Typography } from "@mui/material";
import React from "react";
import GridTempTextImage2 from "./gridTemp2";
import Laptop from "../../asset/lap1.svg"


export default function AssetManagementSystem() {
  const title = "Asset Management System";
  const subTitle = "Web Application Development";
  const benefit =
    "SAP License Cost Saving, Restricting 3rd Party Access to SAP ERP";
  const launch = "9 Weeks";
  const project = "Maintaining for past 9 years";
  const button = "View Details";

  const content = [
    {
      content:
        "Shipping Execution Management application is used by a chemical division of a multinational consumer goods corporation.",
    },
    {
      content:
        "This web application (integrated with SAP Order Management & Shipping Execution Modules) helps the chemical manufacturer to communicate order updates to Suppliers and transporting companies that are involved in Shipping Execution.",
    },
    {
      content: "Java/J2EE, ABAP, Oracle, Axway MFT",
    },
  ];
  return (
    <GridTempTextImage2
      title={title}
      content={content}
      benefit={benefit}
      launch={launch}
      project={project}
      subTitle={subTitle}
      button={button}
      image={Laptop}
    />
  );
}
