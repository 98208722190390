import React from "react";
import { Box, Button, Grid, IconButton, Typography } from "@mui/material";
import { exploreSolution } from "../../label";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import HomeCardsTemp from "./homeCardsTemp";
import HomeImg2 from "../../asset/homeImg2.png";
import HImg2bg from "../../asset/hImg2bg.png";
import HImg2Chord from "../../asset/hImg2Chord.png";
import HImg2Tri from "../../asset/hImg2BlueTri.png";
import HImg2Stat from "../../asset/hImg2Stat.png";

function BussinessAnalytics() {
  const cardContent = [
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
    {
      title: "Powering Radial’s digital transformation journey",
      content: "Powering Radial’s digital transformation journey",
    },
  ];
  return (
    <Grid container display="flex" color="common.black" spacing={7}>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        justifyContent="center">
        <Box
          sx={{
            position: "relative",
            maxHeight: { xs: 260, sm: 460, md: 620 },
            minHeight: { xs: 250, sm: 450, md: 610 },
            maxWidth: { xs: 260, sm: 460, md: 620 },
            minWidth: { xs: 250, sm: 450, md: 610 },
            // // border: "2px solid black",,
          }}>
          <img
            src={HImg2bg}
            alt={"NoImage"}
            style={{
              position: "absolute",
              height: "70%",
              width: "70%",
              top: "34%",
              bottom: "0%",
              left: "4%",
            }}
          />
          <img
            src={HomeImg2}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "80%",
              height: "100%",
              // bottom: "62%",
            }}
          />
          <img
            src={HImg2Chord}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "25%",
              height: "12%",
              top: "95%",
              right: "65%",
            }}
          />
          <img
            src={HImg2Tri}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "13%",
              height: "12%",
              top: "28%",
              left: "68%",
            }}
          />
          <img
            src={HImg2Stat}
            alt={"NoImage"}
            style={{
              position: "absolute",
              width: "25%",
              height: "25%",
              top: "80%",
              left: "50%",
            }}
          />
        </Box>
      </Grid>
      <Grid
        item
        lg={6}
        md={12}
        sm={12}
        xs={12}
        display="flex"
        alignItems="center"
        justifyContent="center">
        <Grid container spacing={5}>
          <Grid item lg={10}>
            <Typography variant="t60_700">
              {"Business Intelligence & Analytics"}
            </Typography>
          </Grid>
          <Grid item lg={9}>
            <Typography variant="t18_500">
              {
                "Aenean pharetra augue in diam rutrum, ac aliquam velit scelerisque. In ut faucibus justo."
              }
            </Typography>
          </Grid>
          <Grid item lg={4}>
            <Button
              sx={{
                backgroundColor: "transparent",
                border: `1px solid black`,
                color: "common.black",
                width: "100%",
                height: "100%",
              }}>
              {exploreSolution}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid item lg={12}>
        <Grid container spacing={5}>
          <Grid item lg={2}>
            <Typography variant="t32_700">{"Case Studies"}</Typography>
            <Box display={"flex"} flexDirection="row" pt="20px">
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                  mr: "10px",
                }}>
                <ArrowBackIcon />
              </IconButton>
              <IconButton
                sx={{
                  backgroundColor: "transparent",
                  border: `1.5px solid #FD7E3B`,
                  color: "common.orange",
                  width: "20%",
                  height: "20%",
                  borderRadius: "8px",
                  ":hover": {
                    backgroundColor: "common.orange",
                    border: `0px solid whitesmoke`,
                    color: "common.white",
                  },
                }}>
                <ArrowForwardIcon />
              </IconButton>
            </Box>
          </Grid>
          {cardContent.map((m) => (
            <Grid item lg={4} md={6} sm={9} xs={12}>
              <HomeCardsTemp title={m.title} content={m.content} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
}

export default BussinessAnalytics;
